/**
 * DateEditable: A class that handles an editable date field with MM/DD/YYYY auto-formatting
 * and manages states like "saving", "unsaved", "editing", etc.
 */

// Status constants
const SAVING = 'SAVING'
const UNSAVED = 'UNSAVED'
const UNCHANGED = 'UNCHANGED'

export default class DateEditable {
	constructor() {
		this.value = ''
		this.original = ''
		this.changed = false
		this.editing = false
		this.saving = false
		this.format = 'MM/DD/YYYY'
	}

	/**
	 * Get the editable field's status.
	 * @return {String}
	 */
	get status() {
		if (!this.editing) return ''

		if (this.saving) return SAVING

		if (this.changed) return UNSAVED

		return UNCHANGED
	}

	/**
	 * Start editing and set the original value.
	 * @param {String} value
	 */
	edit(value) {
		this.value = value || ''
		this.original = value || ''
		this.editing = true
		this.changed = false // Reset the changed flag
	}

	/**
	 * Handle input event, auto-formatting the date as MM/DD/YYYY.
	 * @param {Event} event
	 */
	onInput(event) {
		let value = event.target.value.replace(/[^0-9/]/g, '') // Remove non-numeric characters except '/'
		let formattedValue = ''

		// Handle backspace separately
		if (event.inputType === 'deleteContentBackward') {
			this.value = event.target.value
			this.changed = this.original !== this.value
			return
		}

		if (event.inputType === 'insertFromPaste') {
			let pastedValue = event.target.value.replace(/\D/g, '')

			if (pastedValue.length >= 2) {
				formattedValue = pastedValue.substring(0, 2) + '/'
				pastedValue = pastedValue.substring(2)
			}

			if (pastedValue.length >= 2) {
				formattedValue += pastedValue.substring(0, 2) + '/'
				pastedValue = pastedValue.substring(2)
			}

			formattedValue += pastedValue

			event.target.value = formattedValue
			this.value = formattedValue
			this.changed = this.original !== formattedValue

			return
		}

		// Split the input value by slashes to check each section (MM/DD/YYYY)
		const parts = value.split('/').filter(Boolean) // Remove empty sections

		if (parts.length > 0) {
			// Handle month part (MM)
			let month = parts[0]

			// If only one digit and user added `/`, prepend '0'
			if (month.length === 1 && value.includes('/')) {
				month = '0' + month
			}

			formattedValue = month
			if (month.length === 2) {
				formattedValue += '/'
			}
			if (month.length > 2) {
				formattedValue = month.slice(0, 2) + '/' + month.slice(2)
			}
		}

		if (parts.length > 1) {
			// Handle day part (DD)
			let day = parts[1]
			// If only one digit and user added `/`, prepend '0'
			if (day.length === 1 && value.split('/').length > 2) {
				day = '0' + day
			}

			if (day.length > 2) {
				formattedValue += day.slice(0, 2) + '/' + day.slice(2)
			} else {
				formattedValue += day
				if (day.length === 2) {
					formattedValue += '/'
				}
			}
		}

		if (parts.length > 2) {
			// Handle year part (YYYY)
			let year = parts[2]
			formattedValue += year
		}
		// Set the formatted value to the input field
		event.target.value = formattedValue
		this.value = formattedValue

		// Mark as changed if it differs from the original
		this.changed = this.original !== formattedValue
	}

	/**
	 * Reset the editable field back to its original state.
	 */
	reset() {
		this.value = ''
		this.original = ''
		this.changed = false
		this.editing = false
		this.saving = false
	}
}
