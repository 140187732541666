<template>
	<div v-if="call" class="call">
		<div class="call-action-bar-new">
			<div class="call-actions">
				<router-link :to="{ name: 'app.calls' }" class="call-action">
					<span class="call-action-icon">
						<BackIcon />
					</span>
					Back
				</router-link>
			</div>
			<div class="call-actions">
				<send-to-emr
					v-if="shouldShowSendToEMR"
					:call="call"
					:emr-options="parsedEmrOptions"
				/>
				<call-types-dropdown
					v-if="
						callTypesAll.length > 0 &&
							((isUrgent && isComplete) || !isUrgent)
					"
					:call="call"
					:call-types="callTypesAll"
				/>
				<button
					v-if="!call.completed_at"
					class="call-action"
					@click.prevent="panels.complete = true"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'check']"
							aria-hidden="true"
						/>
					</span>
					Complete
				</button>
				<button
					v-if="isUrgent && !isCallDismissed && !call.completed_at"
					class="call-action"
					@click.prevent="onDismissSubmit"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'circle']"
							aria-hidden="true"
						/>
					</span>
					Dismiss
				</button>
				<button
					v-if="isUrgent"
					class="call-action"
					@click.prevent="panels.reassign = true"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'people-carry']"
							aria-hidden="true"
						/>
					</span>
					Reassign
				</button>
				<button
					class="call-action"
					@click.prevent="panels.notes.create = true"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'sticky-note']"
							aria-hidden="true"
						/>
					</span>
					Add Note
				</button>
				<button class="call-action" @click.prevent="exportToPdf">
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'file-pdf']"
							aria-hidden="true"
						/>
					</span>
					Export
				</button>
			</div>
		</div>
		<div v-if="hasRecordings" class="audio-container">
			<div class="play-recording-bar mt-0 mx-0">
				<div class="audio-controls">
					<span class="play-all-label">
						Play All
					</span>
					<div
						v-for="(recording, index) in allRecordings"
						:key="recording.type"
						:style="{
							zIndex: index === currentIndex ? '20' : index + 10,
							display: index === currentIndex ? 'flex' : 'none',
						}"
						class="flex align-items-center audio-wrapper"
					>
						<button
							class="control-button-play"
							@click="toggleRecording(recording.ref)"
						>
							<PauseIcon v-if="isPlaying" />
							<PlayIcon v-else />
						</button>
						<button
							class="control-button-skip"
							@click="skipBack(recording.ref)"
						>
							<SkipBackIcon :opacity="isPlaying ? 1 : 0.5" />
						</button>
						<span
							v-if="recording.ref === 'name-audio'"
							class="duration"
							>{{ currentTimeFormattedName }} /
							{{ durationFormattedName }}</span
						>
						<span
							v-if="recording.ref === 'dob-audio'"
							class="duration"
							>{{ currentTimeFormattedDob }} /
							{{ durationFormattedDob }}</span
						>
						<span
							v-if="recording.ref === 'message-audio'"
							class="duration"
							>{{ currentTimeFormattedMessage }} /
							{{ durationFormattedMessage }}</span
						>
						<input
							v-if="recording.ref === 'name-audio'"
							v-model="nameCurrentTime"
							type="range"
							min="0"
							:max="nameDuration"
							step="0.01"
							class="audio-slider"
							@input="seekName"
						/>
						<input
							v-if="recording.ref === 'dob-audio'"
							v-model="dobCurrentTime"
							type="range"
							min="0"
							:max="dobDuration"
							step="0.01"
							class="audio-slider"
							@input="seekDob"
						/>
						<input
							v-if="recording.ref === 'message-audio'"
							v-model="messageCurrentTime"
							type="range"
							min="0"
							:max="messageDuration"
							step="0.01"
							class="audio-slider"
							@input="seekMessage"
						/>
						<audio
							:ref="recording.ref"
							data-key="playAll"
							:src="recording.src"
							controls
							class="audio-player"
							controlsList="nodownload noplaybackrate novolume"
							@ended="onRecordingEnd(recording.ref)"
							@timeupdate="
								updateProgress(recording.type, recording.ref)
							"
							@loadedmetadata="
								setDuration(recording.type, recording.ref)
							"
						></audio>
					</div>
				</div>
			</div>
		</div>

		<div class="call-dashboard_container">
			<div class="call-dashboard_container-left">
				<div class="card">
					<div class="card-title">Message</div>
					<div class="card-body message-container">
						<div
							v-show="isMessageDropdownOpen"
							:class="[
								'transition-width duration-500 ease-in-out',
								{
									'w-1/4': !isMessageDropdownOpen,
									'w-full': isMessageDropdownOpen,
								},
							]"
						>
							<!--message block-->
							<div class="block">
								<div class="message-date">
									<span>
										Received:
										{{ call.created_at | toDateTime }}
									</span>
								</div>
								<div class="call-message-edit">
									<div
										v-if="message.editing"
										id="call-message-editable"
										ref="message"
										contenteditable="true"
										@blur="message.update($event)"
										@keyup="message.onKeyUp($event)"
										@keyup.esc.prevent.stop="onMessageReset"
										@keydown.ctrl.enter.exact="
											onSaveMessage
										"
										@keydown.meta.enter="onSaveMessage"
										v-text="message.value"
									></div>
									<div v-else-if="call.patient_message">
										<div
											id="call-message"
											contenteditable="false"
											v-html="call.patient_message"
										/>
									</div>
									<button
										v-else
										type="button"
										class="w-100 p-2 px-2 text-gray-500 text-left focus:outline-none"
										@click="onEditMessage"
									>
										Click to edit the call transcription.
									</button>
									<div
										class="w-100 text-xs text-left uppercase font-roboto font-medium tracking-wider h-4 mt-4 d-block mb-1 ml-2"
									>
										<span
											v-if="message.editing"
											class="text-xs font-medium text-gray-600"
										>
											Press <code>ESC</code> to cancel;
											<code>CTRL/CMD</code> +
											<code>Enter</code> to save.
										</span>
									</div>
								</div>
							</div>
							<!--Action buttons-->
							<div class="w-100 pt-2 px-3 pb-3">
								<div class="button-container">
									<div class="chip-set">
										<chip
											v-if="isComplete"
											condensed
											:icon="['fal', 'check']"
											class="bg-green-100 text-green-500 border-green-200"
										>
											Complete
										</chip>
										<chip
											v-if="isCallDismissed"
											condensed
											:icon="['far', 'circle']"
											class="bg-purple-100 text-purple-500 border-purple-200"
										>
											Dismissed
										</chip>

										<chip v-if="isRsmCall" condensed>
											Voicemail
										</chip>

										<chip
											v-if="call.is_urgent"
											condensed
											:icon="['fal', 'clock']"
											class="bg-orange-100 text-orange-500 border-orange-200"
										>
											Urgent
										</chip>

										<chip v-else condensed>
											Non-Urgent
										</chip>
										<chip
											v-for="type in call.types"
											:key="type.id"
											condensed
										>
											{{ type.name }}
										</chip>
									</div>
									<div class="action-container">
										<div
											class="status-message"
											:class="{
												'text-green-700':
													message.status === 'SAVING',
												'text-red-700':
													message.status ===
													'UNSAVED',
												'text-gray-600':
													message.status ===
													'UNCHANGED',
											}"
											v-text="message.status"
										></div>
										<div class="button-container">
											<div
												v-if="
													hasMessageRecording &&
														parseInt(
															messageRecording.duration
														) > 0
												"
												class="mt-1"
											>
												<button
													v-if="
														!activeRecording[
															'message-audio'
														]
													"
													type="button"
													data-tooltip="Play"
													data-position="bottom"
													@click.prevent="
														playSingleRecording(
															'message-audio'
														)
													"
												>
													<PlayIcon />
												</button>
												<button
													v-else
													type="button"
													data-tooltip="Pause"
													data-position="bottom"
													@click.prevent="
														pauseSingleRecording(
															'message-audio'
														)
													"
												>
													<PauseIcon />
												</button>
											</div>

											<button
												v-if="!message.editing"
												:disabled="message.editing"
												type="button"
												data-tooltip="Edit"
												data-position="bottom"
												@click="onEditMessage"
											>
												<font-awesome-icon
													fixed-width
													class="btn-icon text-gray-700 new-design-icon"
													:icon="['fal', 'pencil']"
												/>
											</button>

											<button
												v-if="message.editing"
												:disabled="!message.editing"
												type="button"
												data-tooltip="Cancel"
												data-position="bottom"
												data-cy="cancel-button"
												@click.stop="onMessageReset"
											>
												<font-awesome-icon
													fixed-width
													class="btn-icon text-gray-700 new-design-icon"
													:icon="[
														'fal',
														'times-circle',
													]"
												/>
											</button>

											<button
												v-if="message.editing"
												type="button"
												:disabled="
													!message.editing ||
														!message.changed ||
														message.saving
												"
												data-tooltip="Save"
												data-position="bottom"
												@click="onSaveMessage"
											>
												<font-awesome-icon
													fixed-width
													class="btn-icon text-gray-700 new-design-icon"
													:icon="['fal', 'save']"
												/>
											</button>
										</div>
									</div>
								</div>
								<div v-show="false" v-if="hasOutboundCalls">
									<hr class="mt-2 mb-3" />
									<div class="flex justify-between">
										<h3
											class="inline-flex items-center font-roboto font-medium text-lg py-2 mb-2 text-gray-800"
										>
											Outbound Calls ({{
												call.outbound_calls.length
											}})
										</h3>
										<button
											class="focus:outline-none"
											@click="toggleOutbondDropdown"
										>
											<font-awesome-icon
												:icon="[
													'fas',
													isOutbondDropdownOpen
														? 'chevron-up'
														: 'chevron-down',
												]"
												class="text-lg text-gray-600"
											/>
										</button>
									</div>
									<div v-if="isOutbondDropdownOpen">
										<div
											v-for="(outboundCall,
											$index) in call.outbound_calls"
											:key="outboundCall.sid"
											class="flex px-2 pb-0 flex-col"
											:class="{
												'border-b':
													$index + 1 !=
													call.outbound_calls.length,
											}"
										>
											<div class="flex flex-col my-2">
												<div
													class="flex items-center justify-between"
												>
													<font-awesome-icon
														class="mr-4 text-material"
														:icon="['fal', 'phone']"
													></font-awesome-icon>

													<span
														class="mr-auto text-sm text-gray-800 font-medium"
													>
														{{
															call.callback_number
																| formatPhoneNumber
														}}
													</span>

													<div
														v-for="record in outboundCall.recordings"
														v-show="
															parseInt(
																record.duration
															) > 0
														"
														:key="record.sid"
														class="flex items-center"
													>
														<audio-player
															:url="record.url"
															:color="
																'text-gray-800'
															"
														/>
														<span
															class="text-xs text-gray-600 ml-2"
														>
															{{
																record.duration
															}}s
														</span>
													</div>
													<div
														class="flex items-center justify-end text-gray-600 text-xs font-normal"
													>
														{{
															outboundCall.created_at
																| toDateTime
														}}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-title">
						<div class="card-title_left">
							<div class="card-title_left_name">Notes</div>
						</div>
						<div class="card-title_right">
							<button @click="toggleCallNotesDropdown">
								<AccordionIconClose
									v-if="isCallNotesDropdownOpen"
									class="accordion-icon"
								/>
								<AccordionIconOpen
									v-else
									class="accordion-icon"
								/>
							</button>
						</div>
					</div>

					<div v-show="isCallNotesDropdownOpen">
						<div
							v-for="currentNote in notes"
							:key="currentNote.id"
							class="card-list note-list"
						>
							<div class="card-list-container">
								<div class="note-list__title-section">
									<div class="content-item">
										<div class="content-item_title">
											{{
												getNoteUserFullName(currentNote)
											}}
										</div>
									</div>
									<div class="date-container">
										<span class="date">
											{{
												currentNote.created_at
													| toDateTime
											}}
										</span>
									</div>
								</div>
								<div class="note-list__description-section">
									<div class="content-item_description">
										{{ currentNote.content }}
									</div>
									<div
										v-if="canEditNote(currentNote)"
										class="note_button-container"
									>
										<button
											type="button"
											data-tooltip="Edit Note"
											data-position="bottom"
											aria-label="Edit Note"
											class="new-design-icon"
											@click.prevent="
												onEditNoteClick(
													call,
													currentNote
												)
											"
										>
											<font-awesome-icon
												class="new-design-icon"
												:icon="['fal', 'pencil']"
											/>
										</button>
										<button
											type="button"
											data-tooltip="Delete Note"
											data-position="bottom"
											class="new-design-icon"
											@click.prevent="
												onDeleteNoteClick(
													call,
													currentNote
												)
											"
										>
											<DeleteIcon />
										</button>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="notes.length === 0"
							class="card-no-item-item"
						>
							<span>
								No notes to display.
							</span>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-title">
						<div class="card-title_left">
							<div class="card-title_left_name">History</div>
						</div>
						<div class="card-title_right">
							<button @click="toggleDropdown">
								<AccordionIconClose
									v-if="isDropdownOpen"
									class="accordion-icon"
								/>
								<AccordionIconOpen
									v-else
									class="accordion-icon"
								/>
							</button>
						</div>
					</div>

					<div v-show="isDropdownOpen">
						<div
							v-for="event in events"
							:key="event.id"
							class="card-list"
						>
							<div class="icon-container">
								<font-awesome-icon
									v-if="event.type === 'create'"
									class="icon"
									:icon="['far', 'phone-alt']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'urgent_forward'"
									class="icon"
									:icon="['far', 'share']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'archive'"
									class="icon"
									:icon="['far', 'archive']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'update'"
									class="icon"
									:icon="['far', 'user-plus']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'complete'"
									class="icon"
									:icon="['far', 'check']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'response'"
									class="icon"
									:icon="['far', 'comment-lines']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'response-call'"
									class="icon"
									:icon="['far', 'phone-plus']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'dismiss'"
									class="icon"
									:icon="['far', 'circle']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'note'"
									class="icon"
									:icon="['far', 'clipboard']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'callback'"
									class="icon"
									:icon="['far', 'phone-volume']"
								/>
								<font-awesome-icon
									v-else-if="event.type === 'call_ended'"
									class="icon"
									:icon="['far', 'phone-slash']"
								/>
								<img
									v-else-if="
										event.type === 'add_coordination_note'
									"
									:src="mrnHistoryIcon"
									alt="MRN History Icon"
									class="icon"
								/>
								<font-awesome-icon
									v-else
									class="icon"
									:icon="['far', 'bell']"
								/>
							</div>
							<div class="content-container">
								<div class="summary-container">
									<span class="summary">
										{{ event.summary }}
										{{
											event.type === 'complete' &&
											call.completion_message &&
											call.completion_message !==
												'No message provided'
												? `- "${call.completion_message}"`
												: ''
										}}
									</span>
								</div>
								<div class="date-container">
									<span class="date">
										{{ event.created_at | toDateTime }}
									</span>
								</div>
							</div>
						</div>
						<div
							v-if="events.length === 0"
							class="card-no-item-item"
						>
							<span>
								No history to display.
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="call-dashboard_container-right">
				<div class="card">
					<div v-if="!isMRNEditing" class="card-title">
						<div class="card-title_left">
							<div class="card-title_left_name">
								Patient Details
							</div>
						</div>
						<div class="card-title_right">
							<div v-if="isEMRConfigured">
								<div
									v-if="isMultipleMRN"
									data-tooltip="Multiple MRN"
									data-position="bottom"
									@click="onClickMultiMRN"
								>
									<MultipleMRNIcon />
								</div>
								<div
									v-else-if="hasMRN"
									data-tooltip="MRN Linked"
									data-position="bottom"
								>
									<MRNIcon />
								</div>
								<div
									v-else
									data-tooltip="No MRN"
									data-position="bottom"
								>
									<NoMRNIcon />
								</div>
							</div>
							<div
								data-tooltip="Patient Profile"
								data-position="bottom"
							>
								<UserIcon />
							</div>
						</div>
					</div>
					<div v-else class="card-title">
						<div class="card-title_left">
							<div class="card-title_left_name">
								Multiple MRN Profile
							</div>
							<div class="card-title_left_name-description">
								Please select a profile that matches the
								patient.
							</div>
						</div>
					</div>
					<div class="card-body">
						<!-------------------------------Edit MRN -------------------------------------------------------------------------------------->
						<div v-if="isMRNEditing">
							<div
								v-for="MRN in multipleMRN"
								:key="MRN.mrn"
								class="multi-mrn_list"
							>
								<div class="form-item">
									<label class="form-item__label"
										>First Name</label
									>
									<div class="form-item__inputField">
										{{ MRN.first_name }}
									</div>
								</div>
								<div class="form-item">
									<label class="form-item__label"
										>Last Name</label
									>
									<div class="form-item__inputField">
										{{ MRN.last_name }}
									</div>
								</div>
								<div class="form-item">
									<label class="form-item__label"
										>Date of Birth</label
									>
									<div class="form-item__inputField">
										{{
											MRN.date_of_birth
												| toDateTimeFormat('MM/DD/YYYY')
										}}
									</div>
								</div>
								<div class="form-item">
									<label class="form-item__label"
										>Gender</label
									>
									<div class="form-item__inputField">
										{{ patientGender }}
									</div>
								</div>
								<div class="form-item">
									<label class="form-item__label">MRN</label>
									<div class="form-item__inputField">
										<div
											class="form-item__inputField-input label-only"
										>
											{{ MRN.mrn }}
										</div>
										<button
											type="button"
											data-tooltip="Select"
											data-position="bottom"
											data-cy="select-button"
											class="mrn-button"
											@click.stop="onSaveMRNnumber(MRN)"
										>
											<CheckIcon />
										</button>
									</div>
								</div>
							</div>
						</div>
						<!-------------------------------MRN -------------------------------------------------------------------------------------->
						<div v-else>
							<div class="form-item">
								<div v-if="isEMRConfigured">
									<label class="form-item__label">{{
										firstnameLabel
									}}</label>
									<div class="form-item__inputField">
										<div
											v-if="callDetails.firstName.editing"
											class="form-item__inputField-input"
										>
											<div
												ref="firstName"
												class="form-item__inputField--editable"
												contenteditable="true"
												@blur="
													callDetails.firstName.update(
														$event
													)
												"
												@keyup="
													callDetails.firstName.onKeyUp(
														$event
													)
												"
												@keyup.esc.prevent.stop="
													callDetails.firstName.reset
												"
												v-text="
													callDetails.firstName.value
												"
											></div>
											<button
												id="clearButton"
												class="clear-button"
												aria-label="Clear"
												data-tooltip="Clear"
												data-position="bottom"
												data-cy="clear-button"
												@click.stop="onClearFirstname"
											>
												<InputCross />
											</button>
										</div>

										<div
											v-else
											class="form-item__inputField--nonEditable"
											contenteditable="false"
											v-html="patientFirstName"
										></div>
										<div
											class="form-item__audioPlayer"
											:class="{
												editForm:
													callDetails.firstName
														.editing,
											}"
										>
											<div
												v-if="
													hasNameRecording &&
														parseInt(
															nameRecording.duration
														) > 0
												"
												class="flex"
											>
												<button
													v-if="
														!activeRecording[
															'name-audio'
														]
													"
													type="button"
													data-tooltip="Play"
													data-position="bottom"
													@click.prevent="
														playSingleRecording(
															'name-audio'
														)
													"
												>
													<PlayIcon />
												</button>
												<button
													v-else
													type="button"
													data-tooltip="Pause"
													data-position="bottom"
													@click.prevent="
														pauseSingleRecording(
															'name-audio'
														)
													"
												>
													<PauseIcon />
												</button>
											</div>
										</div>
									</div>
								</div>
								<div v-else>
									<label class="form-item__label">Name</label>
									<div class="form-item__inputField">
										<div
											v-if="callDetails.name.editing"
											class="form-item__inputField-input"
										>
											<div
												ref="name"
												class="form-item__inputField--editable"
												contenteditable="true"
												@blur="
													callDetails.name.update(
														$event
													)
												"
												@keyup="
													callDetails.name.onKeyUp(
														$event
													)
												"
												@keyup.esc.prevent.stop="
													callDetails.name.reset;
												"
												v-text="callDetails.name.value"
											></div>
											<button
												id="clearButton"
												class="clear-button"
												aria-label="Clear"
												data-tooltip="Clear"
												data-position="bottom"
												data-cy="clear-button"
												@click.stop="onClearName"
											>
												<InputCross />
											</button>
										</div>

										<div
											v-else
											class="form-item__inputField--nonEditable"
											contenteditable="false"
											v-html="patientName"
										></div>
										<div
											class="form-item__audioPlayer"
											:class="{
												editForm:
													callDetails.name.editing,
											}"
										>
											<div
												v-if="
													hasNameRecording &&
														parseInt(
															nameRecording.duration
														) > 0
												"
												class="flex"
											>
												<button
													v-if="
														!activeRecording[
															'name-audio'
														]
													"
													type="button"
													data-tooltip="Play"
													data-position="bottom"
													@click.prevent="
														playSingleRecording(
															'name-audio'
														)
													"
												>
													<PlayIcon />
												</button>
												<button
													v-else
													type="button"
													data-tooltip="Pause"
													data-position="bottom"
													@click.prevent="
														pauseSingleRecording(
															'name-audio'
														)
													"
												>
													<PauseIcon />
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="isEMRConfigured" class="form-item">
								<div>
									<div v-if="callDetails.middleName.editing">
										<label class="form-item__label"
											>Middle Name</label
										>
										<div class="form-item__inputField">
											<div
												class="form-item__inputField-input"
											>
												<div
													ref="middleName"
													class="form-item__inputField--editable"
													contenteditable="true"
													@blur="
														callDetails.middleName.update(
															$event
														)
													"
													@keyup="
														callDetails.middleName.onKeyUp(
															$event
														)
													"
													@keyup.esc.prevent.stop="
														callDetails.middleName
															.reset;
													"
													v-text="
														callDetails.middleName
															.value
													"
												></div>
												<button
													id="clearButton"
													class="clear-button"
													aria-label="Clear"
													data-tooltip="Clear"
													data-position="bottom"
													data-cy="clear-button"
													@click.stop="
														onClearMiddlename
													"
												>
													<InputCross />
												</button>
											</div>
										</div>
									</div>
									<div v-else-if="patientMiddleName">
										<label class="form-item__label"
											>Middle Name</label
										>
										<div class="form-item__inputField">
											<div
												class="form-item__inputField--nonEditable"
												contenteditable="false"
												v-html="patientMiddleName"
											></div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="isEMRConfigured" class="form-item">
								<div v-if="hasEMRData">
									<label class="form-item__label"
										>Last Name</label
									>
									<div class="form-item__inputField">
										<div
											v-if="callDetails.lastName.editing"
											class="form-item__inputField-input"
										>
											<div
												ref="lastName"
												class="form-item__inputField--editable"
												contenteditable="true"
												@blur="
													callDetails.lastName.update(
														$event
													)
												"
												@keyup="
													callDetails.lastName.onKeyUp(
														$event
													)
												"
												@keyup.esc.prevent.stop="
													callDetails.lastName.reset;
												"
												v-text="
													callDetails.lastName.value
												"
											></div>
											<button
												id="clearButton"
												class="clear-button"
												aria-label="Clear"
												data-tooltip="Clear"
												data-position="bottom"
												data-cy="clear-button"
												@click.stop="onClearLastname"
											>
												<InputCross />
											</button>
										</div>

										<div
											v-else
											class="form-item__inputField--nonEditable"
											contenteditable="false"
											v-html="patientLastName"
										></div>
									</div>
								</div>
								<div v-else-if="callDetails.lastName.editing">
									<label class="form-item__label"
										>Last Name</label
									>
									<div class="form-item__inputField">
										<div
											v-if="callDetails.lastName.editing"
											class="form-item__inputField-input"
										>
											<div
												ref="lastName"
												class="form-item__inputField--editable"
												contenteditable="true"
												@blur="
													callDetails.lastName.update(
														$event
													)
												"
												@keyup="
													callDetails.lastName.onKeyUp(
														$event
													)
												"
												@keyup.esc.prevent.stop="
													callDetails.lastName.reset;
												"
												v-text="
													callDetails.lastName.value
												"
											></div>
											<button
												id="clearButton"
												class="clear-button"
												aria-label="Clear"
												data-tooltip="Clear"
												data-position="bottom"
												data-cy="clear-button"
												@click.stop="onClearLastname"
											>
												<InputCross />
											</button>
										</div>
									</div>
								</div>
							</div>

							<div class="form-item">
								<label class="form-item__label"
									>Date of Birth</label
								>
								<div class="form-item__inputField">
									<div
										v-if="callDetails.dob.editing"
										class="form-item__inputField-input"
									>
										<input
											ref="dob"
											type="text"
											class="form-item__inputField--editable outline-none font-medium"
											:value="callDetails.dob.value"
											placeholder="MM/DD/YYYY"
											maxlength="10"
											@input="
												callDetails.dob.onInput($event)
											"
										/>
										<button
											id="clearButton"
											class="clear-button-dob"
											aria-label="Clear"
											data-tooltip="Clear"
											data-position="bottom"
											data-cy="clear-button"
											@click.stop="onClearDob"
										>
											<InputCross />
										</button>
									</div>

									<div
										v-else
										class="form-item__inputField--nonEditable"
										contenteditable="false"
										v-html="patientDob"
									></div>
									<div class="form-item__audioPlayer">
										<div
											v-if="
												hasDobRecording &&
													parseInt(
														dobRecording.duration
													) > 0
											"
											class="flex"
										>
											<button
												v-if="
													!activeRecording[
														'dob-audio'
													]
												"
												type="button"
												data-tooltip="Play"
												data-position="bottom"
												@click.prevent="
													playSingleRecording(
														'dob-audio'
													)
												"
											>
												<PlayIcon />
											</button>
											<button
												v-else
												type="button"
												data-tooltip="Pause"
												data-position="bottom"
												@click.prevent="
													pauseSingleRecording(
														'dob-audio'
													)
												"
											>
												<PauseIcon />
											</button>
										</div>
									</div>
								</div>
							</div>

							<div v-if="isEMRConfigured" class="form-item">
								<div v-if="hasEMRData">
									<label class="form-item__label"
										>Gender</label
									>
									<div class="form-item__inputField">
										<div
											v-if="callDetails.firstName.editing"
											class="form-item__inputField-radio"
										>
											<div
												class="flex items-center radio-container"
											>
												<input
													id="male"
													v-model="callDetails.gender"
													type="radio"
													name="gender"
													value="MALE"
												/>
												<label
													for="male"
													class="radio-label"
													>Male</label
												>
											</div>
											<div
												class="flex items-center radio-container"
											>
												<input
													id="female"
													v-model="callDetails.gender"
													type="radio"
													name="gender"
													value="FEMALE"
												/>
												<label
													for="female"
													class="radio-label"
													>Female</label
												>
											</div>
										</div>
										<div
											v-else
											class="form-item__inputField--nonEditable"
											contenteditable="false"
											v-html="patientGenderVisible"
										></div>
									</div>
								</div>
								<div v-else-if="callDetails.firstName.editing">
									<label class="form-item__label"
										>Gender</label
									>
									<div class="form-item__inputField">
										<div
											v-if="callDetails.firstName.editing"
											class="form-item__inputField-radio"
										>
											<div
												class="flex items-center radio-container"
											>
												<input
													id="male"
													v-model="callDetails.gender"
													type="radio"
													name="gender"
													value="MALE"
												/>
												<label
													for="male"
													class="radio-label"
													>Male</label
												>
											</div>
											<div
												class="flex items-center radio-container"
											>
												<input
													id="female"
													v-model="callDetails.gender"
													type="radio"
													name="gender"
													value="FEMALE"
												/>
												<label
													for="female"
													class="radio-label"
													>Female</label
												>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								v-if="
									isEMRConfigured &&
										(hasMRN || isMultipleMRN) &&
										!callDetails.firstName.editing
								"
								class="form-item"
							>
								<label class="form-item__label">MRN</label>
								<div class="form-item__inputField">
									<div
										v-if="isMultipleMRN"
										class="form-item__inputField--link"
										contenteditable="false"
										data-tooltip="Multiple MRN"
										data-position="bottom"
										@click="onClickMultiMRN"
									>
										{{ 'Multiple' }}
									</div>
									<div
										v-else
										class="form-item__inputField--nonEditable"
										contenteditable="false"
									>
										{{ callMRN }}
									</div>
								</div>
							</div>
							<div class="form-item">
								<hr />
							</div>
							<div class="form-item">
								<label class="form-item__label"
									>Callback Phone</label
								>

								<div class="form-item__inputField">
									<div
										v-if="callDetails.callbackPhone.editing"
										class="form-item__inputField-input"
									>
										<div
											ref="callbackPhone"
											class="form-item__inputField--editable"
											contenteditable="true"
											@blur="
												callDetails.callbackPhone.update(
													$event
												)
											"
											@keyup="
												callDetails.callbackPhone.onKeyUp(
													$event
												)
											"
											@keyup.esc.prevent.stop="
												callDetails.callbackPhone.reset
											"
											v-text="
												callDetails.callbackPhone.value
											"
										></div>
										<button
											id="clearButton"
											class="clear-button"
											aria-label="Clear"
											data-tooltip="Clear"
											data-position="bottom"
											data-cy="clear-button"
											@click.stop="onClearCallbackPhone"
										>
											<InputCross />
										</button>
									</div>
									<div
										v-else
										class="form-item__inputField--nonEditable"
										contenteditable="false"
										v-html="phoneNumber"
									></div>
								</div>
							</div>
							<div
								v-if="
									PharmacyPhoneFlag &&
										isEMRConfigured &&
										call.pharmacy_phone_number
								"
								class="form-item"
							>
								<label class="form-item__label"
									>Pharmacy Phone</label
								>
								<div class="form-item__inputField">
									<div
										v-if="
											callDetails.pharmacyPhone.editing &&
												pharmacyPhone
										"
										class="form-item__inputField-input"
									>
										<div
											ref="pharmacyPhone"
											class="form-item__inputField--editable"
											contenteditable="true"
											@blur="
												callDetails.pharmacyPhone.update(
													$event
												)
											"
											@keyup="
												callDetails.pharmacyPhone.onKeyUp(
													$event
												)
											"
											@keyup.esc.prevent.stop="
												callDetails.pharmacyPhone.reset
											"
											v-text="
												callDetails.pharmacyPhone.value
											"
										></div>
										<button
											id="clearButton"
											class="clear-button"
											aria-label="Clear"
											data-tooltip="Clear"
											data-position="bottom"
											data-cy="clear-button"
											@click.stop="onClearPharmacyPhone"
										>
											<InputCross />
										</button>
									</div>

									<div
										v-else
										class="form-item__inputField--nonEditable"
										contenteditable="false"
										v-html="pharmacyPhone"
									></div>
								</div>
							</div>
							<div class="form-item">
								<label class="form-item__label">Provider</label>
								<div class="form-item__inputField">
									<div
										class="form-item__inputField--nonEditable "
										:class="{
											editForm: callDetails.dob.editing,
										}"
										contenteditable="false"
									>
										{{
											call.patient_provider
												? call.patient_provider
														.full_name
												: 'N/A'
										}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="card-footer_left"></div>
						<div class="card-footer_right">
							<div
								v-if="isMRNEditing"
								class="card-footer_button-container"
							>
								<button
									type="button"
									class="card-footer_button"
									data-tooltip="Cancel"
									data-position="bottom"
									data-cy="cancel-button"
									@click.stop="onEditMRNReset"
								>
									<font-awesome-icon
										fixed-width
										class="btn-icon text-gray-700 new-design-icon"
										:icon="['fal', 'times-circle']"
									/>
								</button>
							</div>
							<div v-else class="card-footer_button-container">
								<div
									v-if="callDetails.dob.editing"
									class="status-message text-xs font-medium"
									:class="{
										'text-green-700':
											patientDetailStatus === 'SAVING',
										'text-red-700':
											patientDetailStatus === 'UNSAVED',
										'text-gray-600':
											patientDetailStatus === 'UNCHANGED',
									}"
									v-text="patientDetailStatus"
								></div>
								<button
									v-if="callDetails.dob.editing"
									type="button"
									:disabled="isLoading"
									class="card-footer_button"
									:data-tooltip="isLoading ? null : 'Cancel'"
									data-position="bottom"
									data-cy="cancel-button"
									@click.stop="onCallDetailsReset"
								>
									<font-awesome-icon
										fixed-width
										class="btn-icon text-gray-700 new-design-icon"
										:icon="['fal', 'times-circle']"
									/>
								</button>
								<button
									v-if="callDetails.dob.editing"
									type="button"
									:disabled="isLoading"
									class="card-footer_button"
									:data-tooltip="isLoading ? null : 'Save'"
									data-position="bottom"
									@click="onSaveCallDetails"
								>
									<font-awesome-icon
										fixed-width
										class="btn-icon text-gray-700 new-design-icon"
										:icon="['fal', 'save']"
									/>
								</button>
								<button
									v-else
									type="button"
									:disabled="isLoading"
									class="card-footer_button"
									:data-tooltip="isLoading ? null : 'Edit'"
									aria-label="CallEdit"
									@click="onCallDetailsEdit"
								>
									<font-awesome-icon
										fixed-width
										class="btn-icon text-gray-700 new-design-icon"
										:icon="['fal', 'pencil']"
									/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<complete-call-panel
			:show="panels.complete"
			:sid="sid"
			@close="panels.complete = false"
		/>
		<add-note-panel
			:show="panels.notes.create"
			:sid="sid"
			:is-page="false"
			@close="panels.notes.create = false"
		/>
		<delete-note-panel
			:show="panels.notes.delete"
			:sid="sid"
			:note="note"
			:is-page="false"
			@close="panels.notes.delete = false"
		/>
		<edit-note-panel
			:show="panels.notes.update"
			:sid="sid"
			:note="note"
			:is-page="false"
			@close="panels.notes.update = false"
		/>
		<reassign-call-panel
			:show="panels.reassign"
			:call="call"
			@close="panels.reassign = false"
		/>
	</div>
</template>
<script>
import { orderBy } from 'lodash'
import Chip from '@/components/Chip.vue'
import Editable from 'App/Support/Editable'
import DateEditable from 'App/Support/DateEditable'
import { mapActions, mapGetters } from 'vuex'
import AudioPlayer from '@/components/AudioPlayer'
import AddNotePanel from '@/components/Panels/AddNotePanel'
import EditNotePanel from '@/components/Panels/EditNotePanel'
import CallTypesDropdown from '@/components/CallTypesDropdown'
import DeleteNotePanel from '@/components/Panels/DeleteNotePanel'
import CompleteCallPanel from '@/components/Panels/CompleteCallPanel'
import ReassignCallPanel from '@/components/Panels/ReassignCallPanel'
import SendToEmr from '@/components/SendToEmr.vue'
import mrnHistoryIcon from '@/assets/img/mrn_history.svg'
import UserIcon from '@/components/Icons/userIcon.vue'
import NoMRNIcon from '@/components/Icons/NoMRNIcon.vue'
import MRNIcon from '@/components/Icons/MRNIcon.vue'
import MultipleMRNIcon from '@/components/Icons/MultipleMRNIcon.vue'
import InputCross from '@/components/Icons/InputCross.vue'
import AccordionIconClose from '@/components/Icons/AccordionIconClose.vue'
import AccordionIconOpen from '@/components/Icons/AccordionIconOpen.vue'
import CheckIcon from '@/components/Icons/CheckIcon.vue'
import BackIcon from '@/components/Icons/BackIcon.vue'
import DeleteIcon from '@/components/Icons/DeleteIcon.vue'
import SkipBackIcon from '@/components/Icons/SkipBackIcon'
import PlayIcon from '@/components/Icons/PlayIcon'
import PauseIcon from '@/components/Icons/PauseIcon'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment/moment'

// this for deault value for callback, first name, last name and mrn feilds, DOB and patient name
const DEFAULT_VAL = 'N/A'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		DeleteIcon,
		BackIcon,
		CheckIcon,
		AccordionIconOpen,
		AccordionIconClose,
		InputCross,
		MultipleMRNIcon,
		MRNIcon,
		NoMRNIcon,
		UserIcon,
		AddNotePanel,
		AudioPlayer,
		CompleteCallPanel,
		Chip,
		CallTypesDropdown,
		DeleteNotePanel,
		EditNotePanel,
		ReassignCallPanel,
		SendToEmr,
		SkipBackIcon,
		PlayIcon,
		PauseIcon,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the current call should have SendToEMR.
		 */
		shouldShowSendToEMR() {
			const callPatientDetailsMRN = _get(
				this,
				'call.call_patient_details.mrn',
				null
			)
			const partnerEmrSettingsNoteTypes = _get(
				this,
				'$store.getters.partner.emr_settings.note_types',
				'[]'
			)

			const isValidMRN =
				typeof callPatientDetailsMRN === 'string' &&
				callPatientDetailsMRN.trim() !== '' &&
				callPatientDetailsMRN !== 'multiple'

			const noteTypes = JSON.parse(partnerEmrSettingsNoteTypes)
			const hasValidNoteTypes =
				Array.isArray(noteTypes) && noteTypes.length > 0

			return isValidMRN && hasValidNoteTypes
		},

		/**
		 * Determine if the current call has EMR data.
		 */
		hasEMRData() {
			const details = _get(this.call, 'call_patient_details', null)
			return !!(details && !_isEmpty(details))
		},

		/**
		 * Determine patientDetail Status.
		 */
		patientDetailStatus() {
			if (this.isEMRConfigured) {
				if (
					[
						this.callDetails.firstName.status,
						this.callDetails.lastName.status,
						this.callDetails.middleName.status,
						this.callDetails.dob.status,
						this.callDetails.callbackPhone.status,
					].every(status => status === 'SAVING')
				) {
					return 'SAVING'
				}
			} else {
				if (
					[
						this.callDetails.name.status,
						this.callDetails.dob.status,
						this.callDetails.callbackPhone.status,
					].every(status => status === 'SAVING')
				) {
					return 'SAVING'
				}
			}
			if (this.isEMRConfigured) {
				if (
					[
						this.callDetails.name.status,
						this.callDetails.firstName.status,
						this.callDetails.lastName.status,
						this.callDetails.middleName.status,
						this.callDetails.dob.status,
						this.callDetails.callbackPhone.status,
					].indexOf('UNSAVED') !== -1 ||
					this.callDetails.gender?.toLowerCase() !==
						this.patientGender?.toLowerCase()
				) {
					return 'UNSAVED'
				}
			} else {
				if (
					[
						this.callDetails.name.status,
						this.callDetails.dob.status,
						this.callDetails.callbackPhone.status,
					].indexOf('UNSAVED') !== -1
				) {
					return 'UNSAVED'
				}
			}
			if (this.isEMRConfigured) {
				if (
					[
						this.callDetails.name.status,
						this.callDetails.firstName.status,
						this.callDetails.lastName.status,
						this.callDetails.middleName.status,
						this.callDetails.dob.status,
						this.callDetails.callbackPhone.status,
					].every(status => status === 'UNCHANGED') &&
					this.callDetails.gender?.toLowerCase() ===
						this.patientGender?.toLowerCase()
				) {
					return 'UNCHANGED'
				}
			} else {
				if (
					[
						this.callDetails.name.status,
						this.callDetails.dob.status,
						this.callDetails.callbackPhone.status,
					].every(status => status === 'UNCHANGED')
				) {
					return 'UNCHANGED'
				}
			}

			return ''
		},

		/**
		 * Below are the computed props to handle audio.
		 */
		durationFormattedName() {
			return this.formatTime(this.nameDuration)
		},

		currentTimeFormattedName() {
			return this.formatTime(this.nameCurrentTime)
		},

		durationFormattedDob() {
			return this.formatTime(this.dobDuration)
		},

		currentTimeFormattedDob() {
			return this.formatTime(this.dobCurrentTime)
		},

		durationFormattedMessage() {
			return this.formatTime(this.messageDuration)
		},

		currentTimeFormattedMessage() {
			return this.formatTime(this.messageCurrentTime)
		},

		/**
		 * Determine if the current call is an RSM call.
		 *
		 * @return {Boolean}
		 */
		isRsmCall() {
			if (
				this.call &&
				this.call.sid &&
				typeof this.call.sid === 'string' &&
				this.call.sid.startsWith('RSM')
			) {
				return true
			} else {
				return false
			}
		},

		/**
		 * Get the current active call to display.
		 *
		 * @return {Object}
		 */
		call() {
			return this.findBySid(this.$route.params.sid)
		},

		/**
		 * Returns a filtered array of call types from `getTypes` if it contains elements, otherwise returns an empty array.
		 */
		callTypesAll() {
			return this.getTypes.length > 0
				? this.getTypes.filter(this.isCallType)
				: []
		},

		/**
		 * Parses the `note_types` from the partner's EMR settings in the store and returns the parsed object, or an empty array if parsing fails.
		 */
		parsedEmrOptions() {
			const noteTypesString = this.$store.getters.partner.emr_settings
				.note_types
			try {
				return JSON.parse(noteTypesString)
			} catch (error) {
				console.error('Failed to parse noteTypesString:', error)
				return []
			}
		},

		/**
		 * Get DOB recording.
		 *
		 * @return {Object}
		 */
		dobRecording() {
			if (!this.hasDobRecording) {
				return
			}

			return this.call.recordings.find(record => record.type === 'dob')
		},

		/**
		 * Get the current call's event messages.
		 *
		 * @return {Array}
		 */
		events() {
			if (!this.call || !this.call.events) {
				return []
			}
			return orderBy(this.call.events, ['created_at'], ['desc'])
		},

		/**
		 * Get the current authenticated user's data.
		 *
		 * @return {Object}
		 */
		currentUser() {
			return this.$store.state.auth.user
		},

		/**
		 * Determine if the current call has recordings.
		 *
		 * @return {Boolean}
		 */
		hasRecordings() {
			return this.call.recordings && this.call.recordings.length > 0
		},

		/**
		 * Determine if the current call has dob recordings.
		 *
		 * @return {Boolean}
		 */
		hasDobRecording() {
			if (!this.hasRecordings) {
				return
			}

			return this.call.recordings.some(record => record.type === 'dob')
		},

		/**
		 * Determine if the current call has dob recordings.
		 *
		 * @return b
		 */
		hasMessageRecording() {
			if (!this.hasRecordings) {
				return
			}

			return this.call.recordings.some(
				record => record.type === 'message'
			)
		},

		/**
		 * Determine if the current call has name recordings.
		 *
		 * @return b
		 */
		hasNameRecording() {
			if (!this.hasRecordings) {
				return
			}

			return this.call.recordings.some(record => record.type === 'name')
		},

		/**
		 * Determine if any outbound calls have been made.
		 *
		 * @return {Boolean}
		 */
		hasOutboundCalls() {
			return this.call?.outbound_calls?.length > 0
		},

		/**
		 * Determine if the current call is complete.
		 *
		 * @return {Boolean}
		 */
		isComplete() {
			return !!this.call.completed_at
		},

		/**
		 * Determine if the current call is dismissed.
		 *
		 * @return {Boolean}
		 */

		isCallDismissed() {
			return (
				this.call.dismissed_by !== null &&
				this.call.dismissed_by !== undefined &&
				this.call.dismissed_by !== ''
			)
		},

		/**
		 * Determine if a call is urgent.
		 *
		 * @return {Boolean}
		 */
		isUrgent() {
			return this.call ? this.call.is_urgent : false
		},

		/**
		 * Get message recording.
		 *
		 * @return {Object}
		 */
		messageRecording() {
			if (!this.hasMessageRecording) {
				return
			}

			return this.call.recordings.find(
				record => record.type === 'message'
			)
		},

		/**
		 * Get name recording.
		 *
		 * @return {Object}
		 */
		nameRecording() {
			if (!this.hasNameRecording) {
				return
			}

			return this.call.recordings.find(record => record.type === 'name')
		},

		/**
		 * Get the current call's notes.
		 *
		 * @return {Array}
		 */
		notes() {
			if (!this.call || !this.call.notes) {
				return []
			}
			const y = [...this.call.notes].sort((a, b) => a.id - b.id)
			return orderBy(y, ['created_at'], ['desc'])
		},

		/**
		 * Get the patient's DOB from the current call.
		 *
		 * @return {String}
		 */
		patientDob() {
			return _get(this.call, 'patient_dob', DEFAULT_VAL)
		},

		/**
		 * Get the patient's name from the current call.
		 *
		 * @return {String}
		 */
		patientName() {
			const patientName = _get(this.call, 'patient_name', DEFAULT_VAL)
			return patientName === '' ? DEFAULT_VAL : patientName
		},

		/**
		 * Get the patient's first name from the current call.
		 *
		 * @return {String}
		 */

		patientFirstName() {
			return this.hasEMRData
				? _get(
						this.call,
						'call_patient_details.first_name',
						DEFAULT_VAL
				  )
				: _get(this.call, 'patient_name', DEFAULT_VAL)
		},

		/**
		 * Get the patient's Gender from the current call.
		 *
		 * @return {String}
		 */
		patientGender() {
			const gender = _get(
				this.call,
				'call_patient_details.gender',
				DEFAULT_VAL
			).toLowerCase()
			return gender === 'female' ? 'Female' : 'Male'
		},

		/**
		 * Get the Read only value of patient's Gender from the current call.
		 *
		 * @return {String}
		 */
		patientGenderVisible() {
			const gender = _get(
				this.call,
				'call_patient_details.gender',
				''
			).toLowerCase()
			if (gender === 'female') {
				return 'Female'
			}
			if (gender === 'male') {
				return 'Male'
			}
			return DEFAULT_VAL
		},

		/**
		 * Get the patient's last name from the current call.
		 *
		 * @return {String}
		 */
		patientLastName() {
			return _get(
				this.call,
				'call_patient_details.last_name',
				DEFAULT_VAL
			)
		},

		/**
		 * Get the patient's middle name from the current call.
		 *
		 * @return {String}
		 */
		patientMiddleName() {
			return _get(this.call, 'call_patient_details.middle_name', null)
		},

		/**
		 * Get the patient's phone number from the current call.
		 *
		 * @return {String}
		 */
		phoneNumber() {
			if (
				!this.call ||
				!this.call.callback_number ||
				this.call.callback_number?.toLowerCase() === 'anonymous'
			)
				return DEFAULT_VAL

			const number = this.call.callback_number.replace(/\D/g, '')
			const formattedNumber = number.replace(
				/^1?(\d{3})(\d{3})(\d{4})$/,
				'$1-$2-$3'
			)

			return formattedNumber
		},

		/**
		 * Get the patient's middle name from the current call.
		 *
		 * @return {String}
		 */
		patientProvider() {
			return _get(this.call, 'patient_provider.full_name', DEFAULT_VAL)
		},

		pharmacyPhone() {
			if (!this.call || !this.call.pharmacy_phone_number)
				return DEFAULT_VAL

			const number = this.call.pharmacy_phone_number.replace(/\D/g, '')
			const formattedNumber = number.replace(
				/^1?(\d{3})(\d{3})(\d{4})$/,
				'$1-$2-$3'
			)

			return formattedNumber
		},
		// will add pharmacy number here once it is available

		/**
		 * Get call's sid.
		 *
		 * @return {String}
		 */
		sid() {
			return this.$route.params.sid
		},

		/**
		 * Checks if the patient's MRN (Medical Record Number) is marked as 'multiple' or if there are multiple MRNs in the system.
		 */
		isMultipleMRN() {
			const mrnNumber = _get(this.call, 'call_patient_details.mrn', null)
			if (
				(typeof mrnNumber === 'string' &&
					mrnNumber.toLowerCase() === 'multiple') ||
				this.multipleMRN.length > 1
			) {
				return true
			}
			return false
		},

		/**
		 * Determines whether the patient's MRN (Medical Record Number) is present in the call details.
		 */
		hasMRN() {
			return Boolean(_get(this.call, 'call_patient_details.mrn', null))
		},

		/**
		 * Retrieves the patient's MRN from the call details, returning 'Multiple' if the MRN is listed as 'multiple'.
		 */
		callMRN() {
			const mrn = _get(this.call, 'call_patient_details.mrn', null)
			if (typeof mrn === 'string' && mrn.toLowerCase() === 'multiple') {
				return 'Multiple'
			}
			return mrn
		},

		/**
		 * Checks if the partner's EMR settings are configured in the Vuex store.
		 */
		isEMRConfigured() {
			return Boolean(this.$store.getters?.partner?.emr_settings)
		},

		/**
		 * Determines the appropriate label for the first name field based on whether EMR data is present and if the first name is being edited.
		 */
		firstnameLabel() {
			if (!this.hasEMRData && this.callDetails.firstName.editing) {
				return 'First Name'
			} else if (
				!this.hasEMRData &&
				!this.callDetails.firstName.editing
			) {
				return 'Name'
			} else {
				return 'First Name'
			}
		},

		/**
		 * Retrieves and sorts all available call recordings by their type based on a predefined order.
		 */
		allRecordings() {
			const records = []

			if (this.call.recordings) {
				this.call.recordings.forEach(recording => {
					if (recording.url) {
						records.push({
							id: recording.id,
							src: recording.url,
							duration: recording.duration,
							type: recording.type,
							ref: `${recording.type}-audio`,
						})
					}
				})
			}
			return records.sort((a, b) => {
				const indexA = this.recordingOrder.indexOf(a.type)
				const indexB = this.recordingOrder.indexOf(b.type)
				return indexA - indexB
			})
		},

		...mapGetters('calls', ['findBySid', 'isRecentCall']),
		...mapGetters({
			getTypes: 'callTypes/get',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Below are the methods for audio control.
		 */
		setDuration(type, ref) {
			const audio = _get(this.$refs, `${ref}[0]`, null)
			this[`${type}Duration`] = audio?.duration || 0
		},

		updateProgress(type, ref) {
			const audio = _get(this.$refs, `${ref}[0]`, null)
			this[`${type}CurrentTime`] = audio?.currentTime || 0
		},

		seekName(event) {
			const audio = _get(this.$refs, `name-audio[0]`, null)

			if (audio) {
				audio.currentTime = event.target.value
			}
		},

		seekDob(event) {
			const audio = _get(this.$refs, `dob-audio[0]`, null)

			if (audio) {
				audio.currentTime = event.target.value
			}
		},

		seekMessage(event) {
			const audio = _get(this.$refs, `message-audio[0]`, null)

			if (audio) {
				audio.currentTime = event.target.value
			}
		},

		formatTime(time = 0) {
			if (typeof time !== 'number' || !isFinite(time)) {
				return '00:00' // Default value for invalid input
			}

			const minutes = Math.floor(time / 60)
				.toString()
				.padStart(2, '0')
			const seconds = Math.floor(time % 60)
				.toString()
				.padStart(2, '0')

			return `${minutes}:${seconds}`
		},

		stopPlaying() {
			const name = _get(this.$refs, 'name-audio[0]', null)
			const dob = _get(this.$refs, 'dob-audio[0]', null)
			const message = _get(this.$refs, 'message-audio[0]', null)
			if (name) {
				name.pause()
				name.currentTime = 0
			}
			if (dob) {
				dob.pause()
				dob.currentTime = 0
			}
			if (message) {
				message.pause()
				message.currentTime = 0
			}
		},

		/**
		 * To reest call.
		 */
		resetCall() {
			this.isMRNEditing = false
			this.callDetails.name.reset()
			this.callDetails.firstName.reset()
			this.callDetails.lastName.reset()
			this.callDetails.middleName.reset()
			this.callDetails.dob.reset()
			this.callDetails.pharmacyPhone.reset()
			this.callDetails.callbackPhone.reset()
			this.multipleMRN = []
		},

		/**
		 * To Play Next Recording.
		 */
		playNextRecording(type) {
			const audio = _get(this.$refs, `${type}[0]`, null)
			if (audio) {
				audio.play()
			}
		},

		/**
		 * To Toggle Recording.
		 */
		toggleRecording(type) {
			if (!this.isPlaying) {
				this.isPlaying = true
				if (this.playSingle) {
					this.playSingleRecording(type)
				} else if (this.playAll) {
					const audio = _get(this.$refs, `${type}[0]`, null)
					if (audio) {
						audio.play()
					}
				} else {
					this.playAll = true
					this.isPlaying = true
					this.playNextRecording(type)
				}
			} else {
				this.isPlaying = false
				const audio = _get(this.$refs, `${type}[0]`, null)
				if (audio) {
					audio.pause()
				}
				this.activeRecording[type] = false
			}
		},

		/**
		 * Handle audio on playback end.
		 */
		onRecordingEnd(type) {
			this.activeRecording[type] = false
			if (
				this.playAll &&
				this.currentIndex < this.allRecordings.length - 1
			) {
				this.currentIndex++

				this.playNextRecording(
					this.allRecordings[this.currentIndex].ref
				)
			} else {
				this.currentIndex = 0
				this.playAll = false
				this.isPlaying = false
				this.stopPlaying()
			}
			this.playSingle = false
		},

		/*
		 * Additional audio methods
		 */

		findIndexByType(type) {
			const index = this.allRecordings.findIndex(
				record => record.ref === type
			)

			if (index !== -1) {
				return index
			} else {
				return -1 // Return -1 if no matching recording is found
			}
		},

		playSingleRecording(type) {
			this.resetPlayer()
			const newIndex = this.findIndexByType(type)

			if (this.currentIndex !== newIndex) {
				this.stopPlaying()
			}
			this.playAll = false
			this.currentIndex = newIndex
			if (this.currentIndex !== -1) {
				this.activeRecording[type] = true
				this.playNextRecording(type)
				this.playSingle = true
				this.isPlaying = true
			}
		},

		pauseSingleRecording(type) {
			const audio = _get(this.$refs, `${type}[0]`, null)
			if (audio) {
				audio.pause()
			}
			this.activeRecording[type] = false
			this.isPlaying = false
		},

		skipBack(type) {
			const audio = _get(this.$refs, `${type}[0]`, null)
			if (audio) {
				const newTime = audio.currentTime - 5
				audio.currentTime = newTime >= 0 ? newTime : 0
			}
		},

		resetPlayer() {
			this.activeRecording['name-audio'] = false
			this.activeRecording['dob-audio'] = false
			this.activeRecording['message-audio'] = false
			this.isPlaying = false
			this.playAll = false
			this.playSingle = false
		},

		/**
		 * Format date.
		 */
		formatDate(date, format) {
			const day = String(date.getDate()).padStart(2, '0')
			const month = String(date.getMonth() + 1).padStart(2, '0')
			const year = date.getFullYear()

			if (format === 'm/d/Y') {
				return `${month}/${day}/${year}`
			}

			return date.toISOString().slice(0, 10)
		},

		/**
		 * Set the current active tab to display.
		 *
		 * @param {String} tab
		 * @return {void}
		 *
		 */

		async onDismissSubmit() {
			try {
				await this.dismiss({
					sid: this.sid,
				})
				this.$alert.success('calls.dismiss.success')
			} catch (e) {
				this.$alert.error('calls.dismiss.error')
			}
		},

		toggleDropdown() {
			this.isDropdownOpen = !this.isDropdownOpen
		},

		toggleCallNotesDropdown() {
			this.isCallNotesDropdownOpen = !this.isCallNotesDropdownOpen
		},

		toggleOutbondDropdown() {
			this.isOutbondDropdownOpen = !this.isOutbondDropdownOpen
		},

		...mapActions({
			dismiss: 'calls/dismiss',
			getCalls: 'calls/get',
		}),

		onClickMultiMRN() {
			this.isMRNEditing = true
			this.onCallDetailsReset()
			this.findMRNProfile(true)
		},

		onEditMRNReset() {
			this.isMRNEditing = false
		},

		onMessageReset() {
			this.message.reset()
		},

		onClearName() {
			this.callDetails.name.value = this.call.patient_name
			this.$refs.name.focus()
			this.callDetails.name.changed = false
		},

		onClearFirstname() {
			this.callDetails.firstName.value = this.call.call_patient_details.first_name
			this.$refs.firstName.focus()
			this.callDetails.firstName.changed = false
		},

		onClearLastname() {
			this.callDetails.lastName.value = this.call.call_patient_details.last_name
			this.$refs.lastName.focus()
			this.callDetails.lastName.changed = false
		},

		onClearMiddlename() {
			this.callDetails.middleName.value = this.call.call_patient_details.middle_name
			this.$refs.middleName.focus()
			this.callDetails.middleName.changed = false
		},

		onClearDob() {
			const isValidDate = this.validateDateFormat(this.call.patient_dob)
			this.callDetails.dob.value = isValidDate
				? this.call.patient_dob
				: ''
			this.$refs.dob.focus()
			this.callDetails.dob.changed = false
		},

		onClearCallbackPhone() {
			this.callDetails.callbackPhone.value = this.phoneNumber
			this.$refs.callbackPhone.focus()
			this.callDetails.callbackPhone.changed = false
		},

		onClearPharmacyPhone() {
			this.callDetails.pharmacyPhone.value = this.call.pharmacy_phone_number
			this.$refs.pharmacyPhone.focus()
		},

		onCallDetailsReset() {
			if (this.isEMRConfigured) {
				this.callDetails.firstName.reset()
				this.callDetails.lastName.reset()
				this.callDetails.middleName.reset()
				this.callDetails.pharmacyPhone.reset()
				this.callDetails.gender = this.callGenderInit
			}
			this.callDetails.name.reset()
			this.callDetails.dob.reset()
			this.callDetails.callbackPhone.reset()
		},

		/**
		 * Determine if the given note can be edited.
		 *
		 * @param {Object} note
		 * @return {Boolean}
		 */
		canEditNote(note) {
			if (!this.currentUser || !note.user) {
				return false
			}

			return this.currentUser.id === note.user.id
		},

		/**
		 * Export the call to a PDF file.
		 *
		 * @return {void}
		 */
		exportToPdf() {
			this.$app.use('App/PDF/CallPDF').make(this.call)
		},

		/**
		 * Fetch the current route's call from the API.
		 *
		 * @param {String} sid
		 * @return {Promise}
		 */
		async getCall(sid) {
			const partner =
				this.$store.getters.partner?.id ||
				parseInt(this.$route.params.partner)

			try {
				const response = await this.$api
					.partners(partner)
					.calls()
					.find(sid)

				const call = response.get('data', {})

				if (this.isRecentCall(call.id)) {
					this.removeRecentCall(call.id)
				}

				const partnerIdParam = parseInt(this.$route.params.partner)

				if (call.partner_id !== partnerIdParam) {
					return this.$router.push({
						params: { partner },
						name: 'app.calls',
					})
				}

				return await this.$store.dispatch('calls/add', call)
			} catch (e) {
				if (e.status === 404) {
					this.$router.push({
						name: 'app.calls',
						params: { partner },
					})
				}

				this.$alert.response(e)
			}
		},

		/**
		 * Fetch all call types.
		 *
		 * @return {void}
		 */
		async getCallTypes() {
			try {
				const response = await this.$api
					.partners()
					.callTypes()
					.get(this.$store.getters.partner.id)

				this.callTypes = response
					.get('data', [])
					.filter(this.isCallType)
			} catch (e) {
				this.$alert.error('default.error')
			}
		},

		/**
		 * Get the user's full name from the given note.
		 *
		 * @param {String}
		 */
		getNoteUserFullName(note) {
			if (!note.user) {
				return 'User Has Been Deleted'
			}

			return `${note.user.first_name} ${note.user.last_name}`
		},

		/**
		 * Determine if the type matches with the call type.
		 *
		 * @type {Boolean} type
		 * @return {Boolean}
		 */
		isCallType(type) {
			if (!this.call) {
				return
			}

			return type.is_urgent === this.call.is_urgent
		},

		/**
		 * Handle the on delete note click event.
		 *
		 * @param {Object} call
		 * @param {Object} note
		 * @return {void}
		 */
		onDeleteNoteClick(call, note) {
			this.panels.notes.delete = true

			this.note = { ...note, call_id: call.id }
		},

		/**
		 * Handle the on edit note click event.
		 *
		 * @param {Object} call
		 * @param {Object} note
		 * @return {void}
		 */
		onEditNoteClick(call, note) {
			this.panels.notes.update = true

			this.note = { ...note, call_id: call.id }
		},

		/**
		 * Handle the user starting to edit the patient's message transcription.
		 *
		 * @return {void}
		 */
		async onEditMessage() {
			this.message.edit(this.call.patient_message)

			await this.$nextTick()

			this.$refs.message.focus()
		},

		async onCallDetailsEdit() {
			if (this.isEMRConfigured) {
				this.callDetails.firstName.edit(this.patientFirstName)
				this.callDetails.lastName.edit(this.patientLastName)
				this.callDetails.middleName.edit(this.patientMiddleName)
				this.callDetails.pharmacyPhone.edit(this.pharmacyPhone)
				this.callDetails.gender = this.patientGender.toUpperCase()
			}
			const isValidDate = this.validateDateFormat(this.patientDob)
			this.callDetails.name.edit(this.call.patient_name)
			this.callDetails.dob.edit(isValidDate ? this.patientDob : '')
			this.callDetails.callbackPhone.edit(this.phoneNumber)

			await this.$nextTick()
			if (this.isEMRConfigured && this.$refs.firstName) {
				this.$refs.firstName.focus()
			} else if (this.$refs.name) {
				this.$refs.name.focus()
			}
		},

		/**
		 * Handle the route/current call change event.
		 *
		 * @return {void}
		 */
		async onRouteChange(to, from) {
			this.message.reset()
			this.callDetails.name.reset()
			this.callDetails.firstName.reset()
			this.callDetails.lastName.reset()
			this.callDetails.middleName.reset()
			this.callDetails.dob.reset()
			this.callDetails.gender = this.callGenderInit
			this.callDetails.callbackPhone.reset()
			this.onEditMRNReset()
			this.stopPlaying()
			this.resetPlayer()

			if (
				from &&
				to.params.sid &&
				to.params.partner !== from.params.partner
			) {
				return this.$router.push({
					name: 'app.calls',
					params: { partner: to.params.partner },
				})
			}

			await [this.getCall(to.params.sid), this.getCallTypes()]
		},

		/**
		 * Handle the message save event.
		 *
		 * @param {MouseEvent|KeyboardEvent}
		 * @return {void}
		 */
		async onSaveMessage(event) {
			this.message.saving = true

			if (event.target === this.$refs.message) {
				this.message.update(event)
			}

			await this.$nextTick()

			await this.updateTranscription(
				'patient_message',
				this.message.value
			)

			this.message.reset()
		},

		async onSaveCallDetails() {
			const isValid = this.validateDob(this.callDetails.dob.value)
			if (!isValid) {
				this.$alert.error('Date format requires MM/DD/YYYY.')
				return
			}

			this.callDetails.name.saving = true
			this.callDetails.firstName.saving = true
			this.callDetails.lastName.saving = true
			this.callDetails.middleName.saving = true
			this.callDetails.dob.saving = true
			this.callDetails.pharmacyPhone.saving = true
			this.callDetails.callbackPhone.saving = true

			if (this.isEMRConfigured) {
				const hasMRNFieldsChanged =
					this.callDetails.firstName.changed ||
					this.callDetails.lastName.changed ||
					this.callDetails.dob.changed ||
					this.callDetails.gender?.toLowerCase() !==
						this.patientGender?.toLowerCase()

				const singleMrn =
					hasMRNFieldsChanged && (await this.findMRNProfile())
				await this.onCallEdit(singleMrn)
			} else {
				const name = this.callDetails.name.value.trim()
				const dob = this.callDetails.dob.value.trim()
				const formattedPhoneNumberNonMRN = this.callDetails.callbackPhone.value
					.trim()
					.replace(/\D/g, '')
				const isValidPhoneNumber = this.validatePhoneNumber(
					formattedPhoneNumberNonMRN
				)

				const errorMessageNonMRN = []
				if (!isValidPhoneNumber) {
					errorMessageNonMRN.push(
						'Invalid phone number. Please enter a valid 10 digit phone number.'
					)
				}

				if (!name) {
					errorMessageNonMRN.push('Patient name cannot be empty.')
				}

				const isValidDate = this.validateDateFormat(dob)
				if (!isValidDate) {
					errorMessageNonMRN.push('Date format requires MM/DD/YYYY.')
				}

				if (errorMessageNonMRN.length) {
					this.$alert.error(errorMessageNonMRN[0])
					this.callDetails.name.reset()
					this.callDetails.dob.reset()
					this.callDetails.callbackPhone.reset()
					return
				}

				await this.updateTranscription('patient_name', name)
				await this.updateTranscription('patient_dob', dob)
				await this.updateTranscription(
					'callback_number',
					formattedPhoneNumberNonMRN
				)

				this.$alert.success('Patient details updated successfully.')
			}

			// Reset form fields after saving
			this.callDetails.name.reset()
			this.callDetails.firstName.reset()
			this.callDetails.lastName.reset()
			this.callDetails.middleName.reset()
			this.callDetails.dob.reset()
			this.callDetails.pharmacyPhone.reset()
			this.callDetails.callbackPhone.reset()
		},

		validateDateFormat(dateString) {
			const datePattern = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/
			return datePattern.test(dateString)
		},

		validatePhoneNumber(phoneNumberVal) {
			const cleanedNumber = phoneNumberVal.replace(/\D/g, '')
			const phonePattern = /^[2-9]\d{9}$/
			return phonePattern.test(cleanedNumber)
		},

		async onCallEdit(mrn) {
			try {
				this.applyingTypeChange = true
				this.isLoading = true
				const formattedDob = this.callDetails.dob.value.trim()
				const isValidDate = this.validateDateFormat(formattedDob)
				if (!isValidDate) {
					this.$alert.error('Date format requires MM/DD/YYYY.')
					this.applyingTypeChange = false
					return
				}

				const formattedPhoneNumber = this.callDetails.callbackPhone.value
					.trim()
					.replace(/\D/g, '')
				const isValidPhoneNumber = this.validatePhoneNumber(
					formattedPhoneNumber
				)

				if (!isValidPhoneNumber) {
					this.$alert.error(
						'Invalid phone number. Please enter a valid 10 digit phone number.'
					)
					this.applyingTypeChange = false
					return
				}

				if (!this.callDetails.firstName.value) {
					this.$alert.error('First Name cannot be empty.')
					return
				}

				if (!this.callDetails.lastName.value) {
					this.$alert.error('Last Name cannot be empty.')
					return
				}

				let callMrnPayload = this.callMRN
				if (
					typeof this.callMRN === 'string' &&
					this.callMRN.toLowerCase() === 'multiple'
				) {
					callMrnPayload = this.callMRN.toLowerCase()
				}
				const callPatientDetails = {
					first_name: this.callDetails.firstName.value,
					last_name: this.callDetails.lastName.value,
					middle_name: this.callDetails.middleName.value,
					date_of_birth: formattedDob,
					gender: this.callDetails.gender,
					callback_number: formattedPhoneNumber,
					mrn: callMrnPayload,
				}

				if (typeof mrn === 'string') {
					callPatientDetails.mrn = mrn === 'no_mrn_found' ? null : mrn
				}

				const response = await this.updateEMR({
					sid: this.call.sid,
					callPatientDetails: callPatientDetails,
					partner: null,
				})
				this.show = false

				if (response.status === 200) {
					this.$alert.success('Patient details updated successfully.')
				} else {
					this.$alert.error(`Update failed: ${response.status}`)
				}
			} catch (e) {
				this.$alert.error(
					_get(e, 'response.message', 'Failed to save call details')
				)
			} finally {
				this.applyingTypeChange = false
				this.isLoading = false
			}
		},

		async onSaveMRNnumber(data) {
			try {
				this.applyingTypeChange = true
				this.isMRNEditing = false
				this.multipleMRN = []

				const callPatientDetails = {
					first_name: _get(
						this.call,
						'call_patient_details.first_name',
						DEFAULT_VAL
					),
					last_name: _get(
						this.call,
						'call_patient_details.last_name',
						DEFAULT_VAL
					),
					date_of_birth: _get(this.call, 'patient_dob', DEFAULT_VAL),
					mrn: data.mrn,
					gender: _get(
						this.call,
						'call_patient_details.gender',
						'MALE'
					),
					callback_number: _get(this.call, 'callback_number', ''),
				}
				const response = await this.updateEMR({
					sid: this.call.sid,
					callPatientDetails: callPatientDetails,
					partner: null,
				})
				this.show = false

				if (response.status === 200) {
					this.$alert.success('Update successful')
				} else {
					this.$alert.error(`Update failed: ${response.status}`)
				}
			} catch (e) {
				this.$alert.error(
					_get(e, 'response.message', 'Failed to save MRN')
				)
			} finally {
				this.applyingTypeChange = false
			}
		},

		/**
		 * Update a call message, patient name, or DOB transcription.
		 *
		 * @param {String} key
		 * @param {String} value
		 * @return {Promise}
		 */
		async updateTranscription(key, value) {
			try {
				// Dispatch the action to update the call attribute
				await this.$store.dispatch('calls/updateCallAttribute', {
					key,
					value,
					id: this.call.id,
				})
			} catch (error) {
				console.error('Error in updating :', error)
				this.$alert.error('An unexpected error occurred.')
			}
		},

		async findMRNProfile(fromIcon) {
			const partnerId = parseInt(this.$route.params.partner)
			const apiUrl = `${process.env.VUE_APP_API_URL}/api/v4/emr/clients?partner_id=${partnerId}`
			const callGender = fromIcon
				? _get(this.call, 'call_patient_details.gender', '')
				: this.callDetails.gender
			try {
				this.isLoading = true
				const response = await fetch(apiUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
					},
					body: JSON.stringify({
						date_of_birth:
							this.callDetails.dob.value || this.patientDob,
						first_name:
							this.callDetails.firstName.value ||
							_get(
								this.call,
								'call_patient_details.first_name',
								null
							) ||
							this.patientName,
						gender: callGender.toUpperCase() === 'MALE' ? 'M' : 'F',
						last_name:
							this.callDetails.lastName.value ||
							_get(
								this.call,
								'call_patient_details.last_name',
								null
							) ||
							this.patientName,
					}),
				}).then(res => res.json())

				this.multipleMRN = response.client_details || []
				if (this.multipleMRN.length === 1 && this.multipleMRN[0].mrn) {
					return this.multipleMRN[0].mrn
				} else if (this.multipleMRN.length > 1) {
					return 'multiple'
				} else if (_isEmpty(this.multipleMRN)) {
					return 'no_mrn_found'
				}
			} catch (e) {
				console.log('e --- ', e)
			} finally {
				this.isLoading = false
			}
		},

		validateDob(dateString) {
			const format = 'MM/DD/YYYY'
			const date = moment(dateString, format, true)

			return date.isValid() && date.format(format) === dateString
		},

		...mapActions('calls', ['removeRecentCall']),
		...mapActions('calls', {
			updateEMR: 'updateEMR',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Show',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for changes.
		 */
		$route: {
			immediate: true,
			handler: 'onRouteChange',
		},
		call(newCall, oldCall) {
			const oldGender = _get(
				oldCall,
				'call_patient_details.gender',
				'MALE'
			)
			const newGender = _get(
				newCall,
				'call_patient_details.gender',
				'MALE'
			)
			if (oldGender !== newGender) {
				this.callDetails.gender = newGender
				this.callGenderInit = newGender
			}
			if (oldCall?.sid !== newCall?.sid) {
				this.resetCall()
				this.callGenderInit = this.callDetails.gender
				this.resetPlayer()
				this.stopPlaying()
			}
		},
	},

	beforeDestroy() {
		// Clean up event listeners when the component is destroyed
		this.stopPlaying()
		this.resetPlayer()
	},

	created() {
		this.callDetails.gender = _get(
			this.call,
			'call_patient_details.gender',
			'MALE'
		)
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			callTypes: [],
			message: new Editable(),
			callDetails: {
				name: new Editable(),
				firstName: new Editable(),
				lastName: new Editable(),
				middleName: new Editable(),
				dob: new DateEditable(),
				gender: 'MALE',
				callbackPhone: new Editable(),
				pharmacyPhone: new Editable(),
			},
			note: null,
			panels: {
				complete: false,
				notes: {
					create: false,
					delete: false,
					update: false,
				},
				reassign: false,
			},
			tab: 'message',
			isDropdownOpen: false,
			isCallNotesDropdownOpen: false,
			isOutbondDropdownOpen: false,
			isMessageDropdownOpen: true,
			currentIndex: 0,
			isPlayingAll: false,
			isPlaying: false,
			playAllQueue: [],
			isMuted: false,
			isMRNEditing: false,
			multipleMRN: [],
			mrnHistoryIcon,
			activeRecording: {
				'message-audio': false,
				'name-audio': false,
				'dob-audio': false,
			},
			recordingOrder: ['name', 'dob', 'message'],
			playAll: false,
			playSingle: false,
			nameDuration: 0,
			nameCurrentTime: 0,
			dobDuration: 0,
			dobCurrentTime: 0,
			messageDuration: 0,
			messageCurrentTime: 0,
			isLoading: false,
			PharmacyPhoneFlag: false,
		}
	},
}
</script>
